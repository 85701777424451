<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Crop Type" vid="commodity_group_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="commodity_group_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('warehouse_config.crop_type') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="loan_rate.commodity_group_id"
                      :options="commodityGroupList"
                      id="commodity_group_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Percentage Rate" vid="percentage_rate" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="percentage_rate"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('warehouse_config.percentage') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="percentage_rate"
                      type="number"
                      v-model="loan_rate.percentage"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Effective Date" vid="effective_date" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="effective_date"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('warehouse_config.effective_date') }} <span class="text-danger">*</span>
                    </template>
                    <flat-pickr class="form-control"
                        v-model="loan_rate.effective_date"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></flat-pickr>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { loanRateStore, loanRateUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getLoanRateData()
      this.loan_rate = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      loan_rate: {
        commodity_group_id: '0',
        percentage: '',
        effective_date: ''
      }
    }
  },
  computed: {
    commodityGroupList: function () {
        return this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
        return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    }
  },
  methods: {
    getLoanRateData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.loan_rate.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${loanRateUpdate}/${this.id}`, this.loan_rate)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, loanRateStore, this.loan_rate)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.error) {
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
            })
        } else {
            this.$toast.error({
            title: 'Error',
            message: result.message
            })
        }
      }
    }
  }
}
</script>
